/** import external dependencies */
import 'jquery';
import Router from './util/Router';
import "waypoints/lib/jquery.waypoints.min";

/** import local dependencies */
import common from './routes/common';
import home from './routes/home'; // Used for front-page.blade.php

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
	/** All pages */
	common,
	/** Home page */
	home,
	/** Posts Archive */
	// blog,
	/** Custom Page Templates */
	// pageTemplateTemplateAbout,
});

/**
 * Polyfill Corrections useful for Vue
 */
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (let i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}
if (typeof NodeList.prototype.forEach !== 'function') {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

/** Load Events */
$(() => routes.loadEvents());

import { Swiper, Autoplay, EffectFade } from "swiper";

export default {
    init() {

        $(document).ready(function () {
            focusable(); // find focusable elements
            skipLinks();
            handleFocus(); // prevent focus on click
            smoothScrolling(); // smooth scrolling
            waypoints();
            downloadAnimation();
            anchorWord();
            ideasList();
            resourcesAnchor();
        });

        /* VARIABLES */
        const pageBody = $('body');

        /*  FUNCTIONS */

        // skip links
        const skipToAnchor = (aid) => {
            var aTag = $(aid);
            var focus = true;
            $('html,body').animate({
                scrollTop: aTag.offset().top
            }, 'slow');
            var first_child = $(aTag.children()[0]);
            var tag = first_child.prop('tagName').toLowerCase();

            if (tag !== 'a' && tag !== 'button' && tag !== 'input' && tag !== 'textarea') {
                if (first_child.attr('tabIndex') !== undefined) {
                    first_child.attr('tabIndex', -1).focus();
                    first_child.removeAttr('tabIndex');
                } else {
                    first_child.focus();
                }
            } else {
                first_child.focus();
            }
        }

        const skipLinks = () => {
            $('section').each(function () {
                const id = $(this).attr('id');
                if (id !== undefined) {
                    // Use the section id to create a label for the items in the skip link list
                    var sectionNameArray = id.split('-');
                    var sectionName = '';
                    for (var i = 0; i < sectionNameArray.length; i++) {
                        var str = sectionNameArray[i];
                        str = str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                            return letter.toUpperCase();
                        });
                        sectionName += str;
                        if (i < sectionNameArray.length - 1) {
                            sectionName += " ";
                        }
                    }
                    var skiplink = "<li><a href='#" + id + "' class='text-link'>Skip to " + sectionName + "</a></li>";
                    $('.skiplinks ul').append(skiplink);
                }
            });

            const skipLinkContainer = $('.skiplinks'),
                skipLink = $('.skiplinks a');

            skipLink.on('focus', function () {
                skipLinkContainer.addClass('show');
            });

            skipLink.on('blur', function () {
                skipLinkContainer.removeClass('show');
            });

            skipLink.on('click', function (e) {
                e.preventDefault();
                skipToAnchor($(this).attr('href'));
            });
        }

        // find focusable items in an element
        const focusable = () => {
            $.extend($.expr[':'], {
                focusable: function(el, index, selector) {
                    return $(el).is('a, button, :input, [tabindex]');
                }
            });
        }

        // prevent focus on click
        const handleFocus = () => {
            $('a, button, .swiper-button').click(function () {
                $(this).addClass('active');
            }).blur(function () {
                $(this).removeClass('active');
            });
        }

        // smooth scrolling
        const smoothScrolling = () => {
            $('a[href*="#"]')
                .not('[href="#"]')
                .not('[href="#0"]')
                .click(function(event) {
                    if (
                        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                        &&
                        location.hostname == this.hostname
                    ) {
                        var target = $(this.hash);
                        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                        if (target.length) {
                            event.preventDefault();
                            $('html, body').animate({
                                    scrollTop: target.offset().top
                                }, 1000
                            );
                        }
                    }
                });
        }

        // wayppoints
        const waypoints = () => {
            $('.waypoint').each(function() {

                new Waypoint({
                    element: $(this),
                    handler: function(direction) {
                        $(this.element).addClass('in-view');
                    }, offset: '75%'
                });
            });
        }

        // download animation
        const downloadAnimation = () => {
            const downloadArrow = document.querySelector('.download-arrow'),
                downloadArrowLength = downloadArrow.getTotalLength();

            downloadArrow.style.strokeDasharray = downloadArrowLength;
            downloadArrow.style.strokeDashoffset = downloadArrowLength;
        }

        // anchor word animation
        const anchorWord = () => {

            try {
                let timeOut = 1000,
                    i = 0;

                const updateWord = () => {
                    const wordContainer = $('#anchorWord');

                    let wordList = wordContainer.attr('data-text') ?
                        wordContainer.attr('data-text').split(',') :
                        ['puppies', 'ice cream', 'rugby', 'yoga', 'surfing', 'writing', 'reading', 'naps', 'coffee', 'Oreos', 'exploring', 'gardening', 'painting', 'soccer', 'cooking', 'fashion', 'family', 'singing']

                    setTimeout(function() {
                        wordContainer.html(wordList[i]);
                        i++;
                        if (i < wordList.length) {
                            updateWord();
                        }
                        else if (i >= wordList.length) {
                            i = 0;
                            updateWord();
                        }
                    }, timeOut)
                }

                updateWord();

                // play/pause
                const anchorBtn = $('#anchorBtn');

                anchorBtn.click(function() {
                    if ($(this).hasClass('paused')) {
                        $(this).removeClass('paused');
                        timeOut = 1000;
                        updateWord();
                    }
                    else {
                        $(this).addClass('paused');
                        timeOut = 9999999;
                        updateWord();
                    }
                });
            } catch (e) {}
        }

        const ideasList = () => {
            const topSwiper = new Swiper(document.querySelector('#listTop .swiper'), {
                modules: [Autoplay, EffectFade],
                init: false,
                autoplay: {
                    delay: 2500,
                },
                loop: true,
                speed: 500,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
            });

            const bottomSwiper = new Swiper(document.querySelector('#listBottom .swiper'), {
                modules: [Autoplay, EffectFade],
                init: false,
                autoplay: {
                    delay: 2500,
                },
                loop: true,
                speed: 500,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
            });

            topSwiper.init();
            bottomSwiper.init();

            const controlBtn = $('#ideasBtn');

            controlBtn.click(function() {

                if ($(this).hasClass('paused')) {
                    $(this).removeClass('paused');
                    topSwiper.autoplay.start();
                    bottomSwiper.autoplay.start();
                }
                else {
                    $(this).addClass('paused');
                    topSwiper.autoplay.stop();
                    bottomSwiper.autoplay.stop();
                }
            });
        }

        const resourcesAnchor = () => {
            $(window).scroll(function() {
                const resources = $('.resources'),
                    resourcesTop = resources.offset().top + 280,
                    resourcesBottom = resourcesTop + resources.outerHeight() - 560,
                    viewportMiddle = $(window).scrollTop() + ($(window).height() / 2),
                    resourcesAnchor = $('.resources__anchors');

                if (viewportMiddle > resourcesTop && viewportMiddle < resourcesBottom) {
                    resourcesAnchor.addClass('fixed');
                }
                else {
                    resourcesAnchor.removeClass('fixed');
                }
            });
        }
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
